/***********************************************
    > img cuadrada 
************************************************/
.bs-img-square{
    width:100%;
    position:relative;
    &::after{
        content:"";
        display:block;
        padding-bottom:100%;
    }
    picture{
        position:absolute;
        width:100%;
        height:100%;
        overflow:hidden;
        display:flex;
        justify-content:center;
        align-items:center;  
        img{
            width:100%;
            height:100%;
            object-fit:contain;
            -o-object-fit:contain;
        }
    }
    &.vertical::after{ //imagen formato 4/3
        padding-bottom:125%;
    }
    &.horizontal::after{ //imagen formato 3/4 
        padding-bottom:75%;
    }
    &.cover picture img{ //imagen formato cover (ocupa todo el espacio del cuadro) 
        object-fit:cover;
       -o-object-fit:cover;
    }
}
// internet explorer 10+
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active){
    .bs-img-square picture {
        display:flex;
        align-items:center;
        justify-content:center;
        display: -ms-flexbox;
        -ms-flex-line-pack: center;
        -ms-flex-pack: center;
    }
    .bs-img-square  picture img{
        position:relative;
        max-width:100%;
        height:auto;
    }
}

.drift-zoom-pane {
    background: var(--zoom-bg, white);
}