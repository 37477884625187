/*****************************************
filtros
******************************************/
*[data-bs='filter.range.size']{
    display:flex;
    justify-content:space-between;
    margin-top:0.25rem;
}

/**********************************
    noUi-target: estilo para establecer el diseño y el fondo del control deslizante

***********************************/
.noUi-target,
.noUi-target * {
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: transparent;
    -webkit-user-select: none;
    -ms-touch-action: none;
    touch-action: none;
    -ms-user-select: none;
    -moz-user-select: none;
    user-select: none;
    -moz-box-sizing: border-box;
    box-sizing: border-box
}
.noUi-target {
    position: relative;
    direction: ltr
}
.noUi-target {
    background: #FAFAFA;
    border-radius: 4px;
    border: 1px solid #D3D3D3;
    box-shadow: inset 0 1px 1px #F0F0F0, 0 3px 6px -5px #BBB
}
/**********************************
   noUi-connect: configura las propiedades relacionadas con el segmento deslizante

***********************************/
.noUi-base,
.noUi-connects {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 1
}
.noUi-connects {
    overflow: hidden;
    z-index: 0
}

.noUi-connects {
    border-radius: 3px
}
.noUi-connect {
    background: #b0bec5;
}
[disabled] .noUi-connect {
    background: #B8B8B8
}
/**********************************
    .noUi-handle	Los mangos reales, visibles. 
    
***********************************/
.noUi-handle {
    position: absolute
}
.noUi-horizontal .noUi-handle {
    width: 34px;
    height: 28px;
    left: -17px;
    top: -6px
}
.noUi-vertical .noUi-handle {
    width: 28px;
    height: 34px;
    left: -6px;
    top: -17px
}
html:not([dir=rtl]) .noUi-horizontal .noUi-handle {
    right: -17px;
    left: auto
}
.noUi-handle {
    border: 1px solid #D9D9D9;
    border-radius: 3px;
    background: #FFF;
    cursor: default;
    box-shadow: inset 0 0 1px #FFF, inset 0 1px 7px #EBEBEB, 0 3px 6px -3px #BBB
}
.noUi-handle:after,
.noUi-handle:before {
    content: "";
    display: block;
    position: absolute;
    height: 14px;
    width: 1px;
    background: #E8E7E6;
    left: 14px;
    top: 6px
}
.noUi-handle:after {
    left: 17px
}
.noUi-vertical .noUi-handle:after,
.noUi-vertical .noUi-handle:before {
    width: 14px;
    height: 1px;
    left: 6px;
    top: 14px
}
.noUi-vertical .noUi-handle:after {
    top: 17px
}
[disabled] .noUi-handle,
[disabled] .noUi-handle,
[disabled] .noUi-target {
    cursor: not-allowed
}
/**********************************
 .noUi-origin	Los elementos conectados a la base, definiendo las ubicaciones del asa.   
    
***********************************/

html:not([dir=rtl]) .noUi-horizontal .noUi-origin {
    left: auto;
    right: 0
}
.noUi-vertical .noUi-origin {
    width: 0
}
.noUi-horizontal .noUi-origin {
    height: 0
}
.noUi-state-tap .noUi-connect,
.noUi-state-tap .noUi-origin {
    -webkit-transition: transform .3s;
    transition: transform .3s
}
.noUi-connect,
.noUi-origin {
    will-change: transform;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    -ms-transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0
}

/**********************************
  noUi Slider filtro de precio
***********************************/

.noUi-state-drag * {
    cursor: inherit!important
}
.noUi-horizontal {
    height: 18px
}

.noUi-vertical {
    width: 18px
}

.noUi-draggable {
    cursor: ew-resize
}
.noUi-vertical .noUi-draggable {
    cursor: ns-resize
}

.noUi-active {
    box-shadow: inset 0 0 1px #FFF, inset 0 1px 7px #DDD, 0 3px 6px -3px #BBB
}
.noUi-pips,
.noUi-pips * {
    -moz-box-sizing: border-box;
    box-sizing: border-box
}
.noUi-pips {
    position: absolute;
    color: #999
}
.noUi-value {
    position: absolute;
    white-space: nowrap;
    text-align: center
}
.noUi-value-sub {
    color: #ccc;
    font-size: 10px
}
.noUi-marker {
    position: absolute;
    background: #CCC
}
.noUi-marker-sub {
    background: #AAA
}
.noUi-marker-large {
    background: #AAA
}
.noUi-pips-horizontal {
    padding: 10px 0;
    height: 80px;
    top: 100%;
    left: 0;
    width: 100%
}
.noUi-value-horizontal {
    -webkit-transform: translate(-50%, 50%);
    transform: translate(-50%, 50%)
}
.noUi-rtl .noUi-value-horizontal {
    -webkit-transform: translate(50%, 50%);
    transform: translate(50%, 50%)
}
.noUi-marker-horizontal.noUi-marker {
    margin-left: -1px;
    width: 2px;
    height: 5px
}
.noUi-marker-horizontal.noUi-marker-sub {
    height: 10px
}
.noUi-marker-horizontal.noUi-marker-large {
    height: 15px
}
.noUi-pips-vertical {
    padding: 0 10px;
    height: 100%;
    top: 0;
    left: 100%
}
.noUi-value-vertical {
    -webkit-transform: translate(0, -50%);
    transform: translate(0, -50%, 0);
    padding-left: 25px
}
.noUi-rtl .noUi-value-vertical {
    -webkit-transform: translate(0, 50%);
    transform: translate(0, 50%)
}
.noUi-marker-vertical.noUi-marker {
    width: 5px;
    height: 2px;
    margin-top: -1px
}
.noUi-marker-vertical.noUi-marker-sub {
    width: 10px
}
.noUi-marker-vertical.noUi-marker-large {
    width: 15px
}
.noUi-tooltip {
    display: block;
    position: absolute;
    border: 1px solid #D9D9D9;
    border-radius: 3px;
    background: #fff;
    color: #000;
    padding: 5px;
    text-align: center;
    white-space: nowrap
}
.noUi-horizontal .noUi-tooltip {
    -webkit-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
    left: 50%;
    bottom: 120%
}
.noUi-vertical .noUi-tooltip {
    -webkit-transform: translate(0, -50%);
    transform: translate(0, -50%);
    top: 50%;
    right: 120%
}