/**********************************
  Discount
***********************************/
$background: var(--discount-bg-color);
$text: var(--discount-txt-color);

:root{
  --discount-bg-color:black;
  --discount-txt-color:white;
}
.bs-discount{
  &[data-bs='cart.item.discount'],
  &[data-bs='product.discount']{
    opacity:0;
  }
}

/****************************************
    > discount v1.0
*****************************************/
.bs-discount{
    position:absolute;
    z-index:50;
    font-size:.75rem;
    @media screen and (min-width:768px){
      font-size:1rem;
    }
    @media screen and (min-width:992px){
      font-size:1.25rem;
    }
    &.bs-discount-xs{
      font-size:.75rem;
    }
    &.right{
        right:0;
    }
    &.bottom{
        bottom:0;
    }
    span, strong{
        box-sizing: border-box;
    }
}
/***********************
  triangulo 
************************/ 
.bs-discount{
  &.triangle{
    width: 6em;
    height:3em;
    &::before{
      content:" ";
      display:block;
      width: 0;
      height: 0;
      border-right: 3em solid transparent;
      border-top: 2em solid $background;
      border-left: 3em solid $background;
      border-bottom: 2em solid transparent;
    }
    span, strong{
      position:relative;
      top: -3em;
      width:100%;
      padding:0 .5em;
      display:block;
      color: $text;
      text-align:left;
    }
    &.right{
      &::before{
        border-right: 3em solid $background;
        border-top: 2em solid $background;
        border-left: 3em solid transparent;
        border-bottom: 2em solid transparent;
      }
      span, strong{
        text-align:right;
      }
    }//.right
  }//.triangle
}

/************************************
  estrella ie11 + otros
*************************************/
.bs-discount.star{
  display:flex;
  transform: rotate(15deg);
  &::before, &::after, span::before, span::after, strong::before, strong::after, &, span, strong{
    height: 2.6em;
    width: 2.6em;
    background:$background;
  }
  &::before, &::after, span::before, span::after, strong::before, strong::after{
    content:" ";
    display:block;
    position:absolute;
  }
  &::before, span::before, strong::before{
    transform: rotate(30deg);
    left:0;
    top:0;
  }
  &::after, span::after, strong::after{
    transform: rotate(60deg);
    top:0;
    left:0;
  }
  span, strong{
    color:$text;
    position:relative;
    display:flex;
    align-items:center;
    justify-content:center;
    transform: rotate(-15deg);
    z-index:150;
  }
  span::before, span::after, strong::before, strong::after{
    z-index:-1;
  }
}

/*************************************
  flag
**************************************/
.bs-discount.flag-down,
.bs-discount.flag-side{
  background: $background;
  color: $text;
  text-align:center;
  width:4em;
  line-height:3em;
}

/*************************************
    flag-down
**************************************/

.bs-discount.flag-down::after{
  content:" ";
  border-left:2em solid $background;
  border-right:2em solid $background;
  border-bottom: 1em solid transparent;
  position:absolute;
  top:100%;
  left:0;
  right:0;
}
/*********************************
  flag-side left
**********************************/
.bs-discount.left.flag-side::after{
  content:" ";
  border-top:1.5em solid $background;
  border-bottom:1.5em solid $background;
  border-right: 1em solid transparent;
  position:absolute;
  top:0;
  left:100%;
  bottom:0;
}
/*********************************
  flag-side right
**********************************/
.bs-discount.right.flag-side::after{
  content:" ";
  border-top:1.5em solid $background;
  border-bottom:1.5em solid $background;
  border-left: 1rem solid transparent;
  position:absolute;
  top:0;
  right:100%;
  bottom:0;
}
/*************************************
  tag
**************************************/
.bs-discount.tag{
  margin:.25rem;
  background: $background;
  color: $text;
  text-align:center;
  width: 4rem;
  line-height: 3.5rem;
}
/******************************
   tag right
******************************/
.bs-discount.tag.right::before,
.bs-discount.tag.right::after{
  content:" ";
  display:block;
  position:absolute;
  right:100%;
}
.bs-discount.tag.right::before{  
  border-top:1.75rem solid transparent;
  border-bottom:1.75rem solid transparent;
  border-right: 1rem solid $background;
  top:0;
  bottom:0;
}
.bs-discount.tag.right::after{
  
  height:0.4rem;
  width:0.4rem;
  background: $text;
  top:50%;
  transform: translateY(-50%);
  border-radius:50%;
}

/******************************
   tag left
******************************/
.bs-discount.tag.left::before,
.bs-discount.tag.left::after{
  content:" ";
  display:block;
  position:absolute;
  left:100%;
}
.bs-discount.tag.left::before{  
  border-top:1.75rem solid transparent;
  border-bottom:1.75rem solid transparent;
  border-left: 1rem solid $background;
  top:0;
  bottom:0;
}
.bs-discount.tag.left::after{
  
  height:0.4rem;
  width:0.4rem;
  background:$text;
  top:50%;
  transform: translateY(-50%);
  border-radius:50%;
}

/******************************
   tag corner badge
******************************/
.bs-discount.corner-badge{
  height:5rem;
  width:5rem;
  overflow:hidden;
  &.right{
    transform: rotate(90deg);
  }
  span, strong{
    display:block;
    position:absolute;
    background:$background;
    color:$text;
    width:7rem;
    text-align:center;
    left:-1.5rem;
    top:1rem;
    transform: rotate(-45deg); 
  } 
}

/*****************************************
  hexagon
******************************************/
.bs-discount.hexagon{
  margin:0.5rem;
  color:$text;
  width:4rem;
  height:2rem;
  background:$background;
  &, span, strong{
    display:flex;
    align-items:center;
    justify-content:center;
  }
  &::before, &::after{
    content:"";
    position:absolute;
    border-left:solid transparent 2rem;
    border-right:solid transparent 2rem;   
  }
  &::before{
    bottom:100%;
    border-bottom:solid $background 1.4rem;
  }
  &::after{
    top:100%;
    border-top:solid $background 1.4rem;
  }
  
}


/*************************************
  > circle
**************************************/
.bs-discount.circle{
  background: $background;
  color: $text;
  height:4em;
  width:4em;
  border-radius:500em;
  display:flex;
  align-items:center;
  justify-content:center;
  margin:-.5em;
}
/**************************************
  form-A
***************************************/

.bs-discount.form-A{
  background: $background;
  color: $text;
  padding:1em;
  margin:0.5em;
  &, &.left{
    border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
  }
  &.right{
    border-radius: 70% 30% 30% 70% / 30% 30% 70% 70%;
  }
}
/*************************
bubble
*************************/
.bs-discount.bubble{
  background: $background;
  color: $text;
  height:3.6em;
  width:3.6em;
  border-radius:500em;
  display:flex;
  align-items:center;
  justify-content:center;
  margin:-.5em;
  span, strong{
    position:relative;
    width:100%;
    height:100%;
    display:flex;
    justify-content:center;
    align-items:center;
    &::after, &::before{
      content:" ";
      display:block;
      width:1rem;
      height:1rem;
      background: $background;
      border-radius:50%;
      position:absolute;
    }
    &:after{
      bottom:-0.125rem;
      left:.35rem;
    }
    &:before{
      top:-0.125rem;
      right:.35rem;
    }
  }
  &::after, &::before{
    content:" ";
    display:block;
    width:.5rem;
    height:.5rem;
    background: $background;
    border-radius:50%;
    position:absolute;
  }
  &:after{
    left:-.125rem;
    bottom:0.5rem;
  }
  &:before{
    right:-.125rem;
    top:0.5rem;
  }
}

/*************************
ovalado
*************************/
.bs-discount.ovalado{
  background-color:$background;
  border-radius: 100%;
  color: $text;
  padding: 0 0.5em;
  font-size: 1.5rem;
}

/*************************
cuadrado
*************************/

.bs-discount.cuadrado {
  top: 8px;
  color: $text;
  background: $background;
  padding: .75rem .65rem;
}