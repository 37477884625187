@import './reset';
@import './animations';
@import './bsale.variables';
@import "./util.scss";

@import './header';
@import './button';
@import './form';
@import './filter';
@import './discounts';
@import './pagination';
@import './text';
//slider here
@import './_slider';
@import './image';
@import './video';
@import './article';
@import './position';
@import '~drift-zoom/dist/drift-basic.css';
@import './froala';

//cart
@import './error.scss';
@import './cart.scss';