/***************************************
    > textos
****************************************/
.bs-trunc{
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display:inline-block;
    line-height: 2rem;
    margin: 0;
}