/**********************
sticky header
-----------------------
function stickyHeader() 
archivo /common/header.js

***********************/
.bs-sticky-header{
    position:fixed !important;
    width: 100%;
    z-index:1000;
}



/***************************
hidden search
****************************/



.bs-search-hidden{  
    &.show{
        display: flex !important;
        position: absolute;
        padding: 0 1rem;
        top: 0;
        left: 0;
        right: 0;
        z-index: 1050;
        bottom: 0;
        background: white;
        align-items: center;
    }
}


/***********************
sticky footer
------------------------
sólo css
************************/
html, body {
    height: 100%;
}
body {
    display: flex;
    flex-direction: column;
    > *{
        flex-shrink: 0;
    }
    .bs-main {
        flex: 1 0 auto;
        padding:1rem 0;
    }
}