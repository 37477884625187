



[data-bs="video"]{
    &.embed-responsive iframe,
    &.embed-responsive img{
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 0;
        background:black;
    }
    img{
        cursor:pointer;
        &:hover + .icon, & + .icon:hover{
            color:white;
            opacity:1;
            
        }

    }
    .icon{
        cursor:pointer;
        position: absolute;
        z-index: 50;
        top: 50%;
        right: 50%;
        transform: translate(50%, -50%);
        margin: 0 auto;
        color: silver;
        opacity: 0.75;
        font-size: 4rem;
    }
}