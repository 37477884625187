@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.fadeOut {
  animation-duration: .5s;
  animation-fill-mode: both;
  animation-name: fadeOut;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fadeIn {
  animation-duration: .5s;
  animation-fill-mode: both;
  animation-name: fadeIn;
}

.drift-zoom-pane.drift-open img {
  object-fit: contain;
  opacity:1!important;
  transition:auto!important;
}

/*loading buttons*/
@keyframes load{
  0%{
    left:0;
    width:0%;
  }

  50%{
    left:0%;
    width:100%
  }
  100%{
    left:100%;
    width:0%;
  }
}