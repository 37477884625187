/*************************************************
    > articulos
**************************************************/
/*
.bs-article{
    img{
        max-width:100%;
        height:auto;
    }
    .fr-fic.fr-dib{
        float:none;
        display:block;
        text-align:center;
        margin: .5rem auto;
    }
    .fr-img-wrap{
        display:flex;
        flex-direction:column;
    }
    .fr-bordered{
        border:.25rem solid;
    }
    .fr-rounded{
        border-radius:1rem;
    }
}

.fr-img-caption.fr-fic.fr-dib .fr-img-wrap{
    margin:0 auto;
}
img.fr-shadow,
.fr-shadow .fr-img-wrap{
    box-shadow: 0 10px 6px -6px rgba(0,0,0,.25), 0 5px 3px -3px rgba(0,0,0,.35) ;
}
@media screen and (min-width:768px){
   .bs-article .fr-fic.fr-dib.fr-fil{
        float:left;
        margin: .5rem 1rem .5rem 0rem;
    }
    .bs-article .fr-fic.fr-dib.fr-fir{
        float:right;
        margin: .5rem 0rem .5rem 1rem;
    } 
}
*/