/************************
Estilos de froala
*************************/ 
.fr-img-wrap{
    overflow:hidden;
}
img, .fr-fic, .fr-img-wrap, .fr-img-caption{
    max-width:100%;
    height:auto;
}
.fr-inner{
    display:block;
    text-align: center;
    padding: 0 1rem; 
}

// flotante //////////////////////////////////////////////////////////////
.fr-dii , .fr-dii .fr-img-wrap{
    display:inline-block;
}
.fr-fil{
    float:left;
    margin-right: 1rem}
.fr-fir{
    float:right;
    margin-left: 1rem
}
// block /////////////////////////////////////////////////////////////////
.fr-dib, 
.fr-dib .fr-img-wrap{
    display:block; 
    float:none; 
    margin:0 auto .5rem;
}
.fr-dib.fr-fil,
.fr-dib.fr-fil .fr-img-wrap{
    margin-left: 0; 
    margin-right:auto; 
    text-align: left;
}
.fr-dib.fr-fir,
.fr-dib.fr-fir .fr-img-wrap{
    margin-left: auto;
    margin-right:0;
    text-align: right;
}

// estilos //////////////////////////////////////////////////////////////////
img.fr-bordered,
.fr-bordered .fr-img-wrap{ //borde de color, marco de foto
    border:.25rem solid;
}
img.fr-rounded,
.fr-rounded .fr-img-wrap{ //esquinas redondeado 
    border-radius:1rem;
}
img.fr-shadow,
.fr-shadow .fr-img-wrap{//sombra
    box-shadow: 0 1rem .50rem -.50rem rgba(0,0,0,.25), 
                0 .5rem .25rem -.25rem rgba(0,0,0,.35) ;
}

// TEXTO 

//Codigo 
pre{
    background-color: #f5f5f5;
    border: 1px solid #ccc;
    padding: .5rem;
    border-radius: .125rem;
    word-break: break-word;
    word-wrap: break-word;
    white-space: pre-wrap;       /* css-3 */
    white-space: -moz-pre-wrap;  /* Mozilla  */
    white-space: -pre-wrap;      /* Opera 4-6 */
    white-space: -o-pre-wrap;    /* Opera 7 */  
}