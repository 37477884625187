/**************************************
    > slider
**************************************/
.slick-dots{
    display:flex;
    list-style:none;
    justify-content:center;
    margin:0;
    padding:0;
    li {
        button{
            font-size:0;
            line-height:0;
            background:none;
            border:none;
            padding:.5rem;
            &:focus{
                outline:none;
            }
            &:before{
                display:block;
                content:"X";
                width:1rem;
                height:1rem;
                background:rgba(0,0,0,0.4);
                border-radius:50%;
            }
        }
        & .active{
            button{
                &:before{
                    background:rgba(0,0,0,0.8);
                }
            }
        }
    }
}

.slider-left > .slick-list > .slick-track {
    margin-left: 0;
}

/***********************************************
    > slick slider
***********************************************/
.slick-slider
{
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
        touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}

.slick-list{
    position: relative;
    display: block;
    overflow: hidden;
    margin: 0;
    padding: 0;
}
.slick-list:focus{
    outline: none;
}
.slick-list.dragging{
    cursor: pointer;
    cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list
{
    -webkit-transform: translate3d(0, 0, 0);
       -moz-transform: translate3d(0, 0, 0);
        -ms-transform: translate3d(0, 0, 0);
         -o-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
}
.slick-track
{
    position: relative;
    top: 0;
    left: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;
}
.slick-track:before,
.slick-track:after{
    display: table;
    content: '';
}
.slick-track:after{clear: both;}
.slick-loading .slick-track{visibility: hidden;}
.slick-slide
{
    display: none;
    float: left;
    height: 100%;
    min-height: 1px;}
[dir='rtl'] .slick-slide
{float: right;}
.slick-slide img{display: block;}
.slick-slide.slick-loading img
{
    display: none;
}
.slick-slide.dragging img
{
    pointer-events: none;
}
.slick-initialized .slick-slide
{
    display: block;
}
.slick-loading .slick-slide
{
    visibility: hidden;
}
.slick-vertical .slick-slide
{
    display: block;
    height: auto;
    border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
    display: none;
}

.bs-horizontal-slider{
    overflow: hidden;
    &.slick-initialized{
        display:flex;
        align-items:center;
    }
	.slick-dots{
       position:absolute;
       bottom:0;
       width:100%;
    }
    .slick-slide{
        height:auto;
        width:100%;
    }
    .slick-track{
        display:flex;
        width:100%;
    }
    .slick-list{
        width:100%;
    }
    .bs-product{
        padding:1rem;
        height:100%;
    }
    .slick-arrow{
      display:flex;
      align-items:center;
      justify-content:center;
      position:relative;
      z-index:10;
      width:2.5rem;
      height:2.5rem;
      &:hover{
          background:silver;
      }
    }
}

// slide genera clases para mostrar u ocultar items del slider antes de que este se inicialice

@mixin slideShow($items, $screenSize: null, $slideInitClass : ".slick-track" ){
    $name: "test";
    @for $i from 1 through $items{
      
        @if $screenSize{
            $name : #{$screenSize}-#{$i};
        }@else{
            $name : #{$i};
        }
      
        .slidesToShow{
            &-#{$name}{
                display:flex;
                >.item{
                    &:nth-child(n+ #{$i + 1}){
                        display:none !important;
                    }
                    &:not(:nth-child(n+ #{$i + 1})){
                        width:#{100%/$i};
                        display:flex !important;
                    }
                }
            }
        }
    }
}//end mixin slideShow
  
  //define 
  @include slideShow(12);
  
  @media screen and (min-width:576px){
    @include slideShow(12,sm);
  }
  @media screen and (min-width:768px){
    @include slideShow(12,md);
  }
  @media screen and (min-width:992px){
    @include slideShow(12,lg);
  }
  @media screen and (min-width:1200px){
    @include slideShow(12,xl);
  }



/***************
owl
***************/

.bs-owl-compact.bs-owl-compact{
    .owl-nav [class*=owl-]:hover {
        background: transparent!important;
        color: black;
    }
    .owl-nav{
        margin:0;
        .owl-prev, .owl-next{
            position:absolute;
            top:0;
            bottom:0;
            &:focus{
                outline: none;
            }
            span{
                font-size:2rem;
                padding:1rem;
            } 
        }
        .owl-prev{
            left:0;
        }
        .owl-next{
            right:0;
        }
    }
    .owl-dots{
        position:absolute;
        bottom:0;
        left:0;
        right:0;
    }
    
}