.bs-pagination ul{
    display:flex;
}

.bs-pagination ul,
.bs-pagination li{
    margin:0;
    padding:0;
    list-style:none;
}
.bs-pagination a,
.bs-pagination span{
    margin:0 .25rem;
    padding:.5rem 1rem;
}