a:not([class*="btn"]){
    &:hover, &:visited, &:active{
        text-decoration:none;
    }
}


a[data-product]{
    display:inline-block;
}

// transparent

@mixin transparent{
    background-color:transparent;
    border-color:transparent;
    border:0;
}
.btn-transparent{
    @include transparent;
    &-sm{
        @media screen and (min-width:576px){
            @include transparent;
        } 
    }
    &-md{
        @media screen and (min-width:768px){
            @include transparent;
        } 
    }
    &-lg{
        @media screen and (min-width:992px){
            @include transparent;
        } 
    }
    &-xl{
        @media screen and (min-width:1200px){
            @include transparent;
        } 
    }
}

/*******************************
    facebook whatsapp
********************************/
.bs-chat{
    display:flex;
    justify-content:center;
    @media screen and (min-width:992px){
        position:fixed;
        bottom:50%;
        right: 1rem;
        flex-direction:column;
        z-index:999; 
        a{
            background:white;
            border-radius:50rem;
            border: 1px solid #eaeaea;
        } 
    }
    a{
        text-decoration:none;
        display:inline-flex;
        justify-content:center;
        align-items:center;
        width:2.5rem;
        height:2.5rem;
        margin:.25rem;
        box-shadow: 0 0 5px 0 rgba(0,0,0,0.3);
    }
}

$color-wp: #25D366;
$color-fb: #0099FF;

.btn{
    text-decoration:none;
    &-whatsapp{
        color: $color-wp;
        &:hover{
            background: $color-wp;
            border-color: $color-wp;
            color:white;
        }
    }
    &-facebook{
        color: $color-fb;
        &:hover{
            background: $color-fb;
            border-color:$color-fb;
            color:white;
        }
    }


    /*&-instagram{
        &, i{
            background:  #cc2366; 
            background: -moz-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%); 
            background: -webkit-linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%); 
            background: linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%); 
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f09433', endColorstr='#bc1888',GradientType=1 );
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
        &:hover{
            background:  #cc2366; 
            background: -moz-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%); 
            background: -webkit-linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%); 
            background: linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%); 
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f09433', endColorstr='#bc1888',GradientType=1 );
            color:white;
            i{
                color:white !important;
                -webkit-text-fill-color: white !important;
                -webkit-background-clip: inherit !important;
                border-color:transparent;
                background:transparent;
            }
        }
    }*/
}

.form-control:focus{
    box-shadow: 0 0 0 0 transparent;
    border-color: #ced4da;
}

.noCollection{
    display: none!important;
}