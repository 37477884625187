/* Dropdown carro */
.btnCart {
  position: relative;
}

.dropdownContent {
  z-index: 99999;
  display: none;
  position: fixed;
  background-color: #fff;
  width: 24rem;
  box-shadow: 0 0.1rem 0.2rem 0 rgba(0, 0, 0, 0.2);
  padding: .5rem;
  right: 0;
  top: 0;
  bottom: 0;
}

.btnCloseDropdown {
  margin-right: .5rem;
  margin-top: .5rem;
}

.containerCartEmpty i {
  font-size: 5rem;
}

@media (max-width:380px){
  .dropdownContent {
    width: 100%;
  }
}

/* Item en el carro */
.containerItemsCart {
  height: 100%;
  overflow-y: auto;
}

.containerItemCart {
  padding: .5rem;
  margin: .5rem 0;
  background-color: #f8f9fa;
}

.detailItemCart {
  display: flex;
  margin-bottom: .5rem;
}

.detailItemCart .bs-img-square {
  width:30%;
}

.detailItemCart .infoItemCart {
  width: 70%;
  text-align: left;
  margin-left: 1rem;
}

.detailItemCart .bs-discount {
  font-size: .7rem;
  margin: .25rem;
}

.detailItemCart .bs-discount.tag {
  width: 2rem;
  line-height: 1.8rem;
}

.infoItemCart a {
  display: block;
  font-size: .9rem;
}

@media (max-width:380px){
  .detailItemCart .bs-discount {
    margin: .25rem;
  }
}

.containerButtonsCart {
  display: flex;
  justify-content: space-between;
}

.quantityButtons {
  width: 60%;
}

.buttonRemoveItem {
  width: 30%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.containerButtonGoToPay {
  display: flex;
  justify-content: flex-end;
}

.containerSubtotal {
  text-align: right;
}

.containerButtonGoToPay a {
  width: 100%;
}

.containerButtonGoToPay button {
  width: 100%;
}

/* Dropdown carro vacío*/
.containerCartEmpty {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.containerCartEmpty i {
  text-align: center;
  color:#000
}

.containerCartEmpty p {
  margin-top: 1rem;
  font-size: 1.25rem;
}

.showElement {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.textPriceUnity {
  display: block;
  font-size: 0.8rem;
  color: darkgray;
  font-weight: 400;
}

.footerDropdownCart {
  padding-bottom: calc(2 * env(safe-area-inset-bottom));
}
