.z-index{
    &-0{z-index:auto;}
    &-1{z-index:1000;}
    &-2{z-index:1010;}
    &-3{z-index:1020;}
    &-4{z-index:1030;}
    &-5{z-index:1040;}
    &-6{z-index:1050;}
    &-7{z-index:1060;}
    &-8{z-index:1070;}
}

/*.position-absolute{
    position:absolute !important;
    &-sm{
      @media screen and (min-width: 576px){
          position:static !important;
      }  
    }
    &-md{
        @media screen and (min-width: 768px){
            position:static !important;
        } 
    }
    &-lg{
        @media screen and (min-width: 992px){
            position:static !important;
        }
    }
    &-xl{
        @media screen and (min-width: 1200px){
            position:static !important;
        }
    }
}*/