//reset
:focus {
    outline: none;
}
.navbar-brand{
    margin-right:0;
}

// hide recaptcha badge
.grecaptcha-badge {
    visibility: hidden;
}
